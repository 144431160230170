import React from "react";
import JourneyFeed from "../../components/JourneyFeed";
import LayoutArchive from "../../components/LayoutArchive";

export const BlogIndexPage = () => {
  return (
    <LayoutArchive>
      <JourneyFeed />
    </LayoutArchive>
  );
};
export default BlogIndexPage;
